<template>
<body>
    <title>Alexis Mayeur | Front-end</title>
    <div class="loadingbar"></div>
    <!-- Mon Discord Embed -->
    <div v-if="discord" class="discordembed">
        <div class="banner"></div>
        <div class="pp">
            <img src="./img/giphy.gif">
        </div>
        <div class="name">Alexis<div class="id">#8584</div>
        </div>
        <div id="textcopy">Alexis#8584</div>
        <div class="line"></div>
        <div class="description">
            <p>Support Technique https://adkynet.com/</p>
            <p>Développeur WEB (Webdesigner)</p>
            <p>Viens DM si tu as besoin de mes services.</p>
            <P>PianoMan</P>
        </div>
        <div class="exit"><i class="fa-solid fa-circle-xmark"></i></div>
    </div>

    <!-- PHP Formulaire pour contact. -->
    <div v-if="mail" class="form">
        <h2>alexis@alexismayeur.fr</h2>
        <form action="mail_handler.php" method="post">
            <input type="text" name="name" placeholder="Votre Nom">
            <input type="email" name="email" placeholder="Votre E-Mail">
            <input type="text" name="subject" placeholder="Sujet">
            <textarea name="message" cols="30" rows="10" placeholder="Bonjour/soir,"></textarea>
            <input type="submit" value="Envoyer">
        </form>
    </div>



    <div class="bod-bod">
        <div class="text">
            <h2>Je suis un développeur Front.</h2>
            <div class="button-social-align">
                <router-link to="about">
                    <div class="button">Voir plus</div>
                </router-link>
                <div class="social">
                    <a href="https://twitter.com/WshAlexis"><i class="fa-brands fa-twitter"></i></a>
                    <i @click="togglediscord" class="fa-brands fa-discord"></i>
                    <i @click="togglemail" class="fa-solid fa-envelope"></i>
                </div>
            </div>
        </div>
        <div class="circle"></div>
    </div>
    <div class="copyright">
        <h2>&copy; AlexisMayeur.fr, Tous droits réservés.</h2>
        <a href="mentions-légales.pdf">
            <p>Mentions-légales</p>
        </a>
    </div>
</body>
</template>
<style>
@import url('css/style.css');
</style>
<script>
export default {
    data: () => {
        return {
            discord: false,
            mail: false
        }
    },
    methods: {
        togglediscord() {
            this.mail = false;
            this.discord = !this.discord;
        },
        togglemail() {
            this.discord = false;
            this.mail = !this.mail;
        }
    }
}
</script>
