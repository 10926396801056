<template>
  <div class="titlename">
    <router-link to="/">
      <p href="" class="typewrite" data-period="2000" data-type='[ "./Alexis..", "./Vatic.."]'><span
          class="wrap"></span></p>
    </router-link>
  </div>
  <router-view />
</template>
<style>
@import url('./views/css/font.css');
/* SCROLLBAR */
::-webkit-scrollbar-track {
  background-color: #303030;
}

::-webkit-scrollbar {
  width: 13px;
  background-color: #242323;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #9c0118, #860202);
  border-radius: 10px;
}
</style>
<script>
export default {
  mounted() {
    var TxtType = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = '';
      this.tick();
      this.isDeleting = false;
    };
    TxtType.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

      var that = this;
      var delta = 200 - Math.random() * 100;

      if (this.isDeleting) { delta /= 2; }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    window.onload = function () {
      var elements = document.getElementsByClassName('typewrite');
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #E74C3C}";
      document.body.appendChild(css);
    };
  }
}
</script>
